.crmPageContainer {
  display: flex;
  height: calc(100% - 48px); /* Subtract tab height from total height */
  width: 100%;
  overflow: hidden;
  position: relative;
  /* background-color: red; */
}

.conversationsContainer {
  width: 0px;
  height: 100%;
  flex-shrink: 0;
  transition: width 0.2s ease-out;
  position: relative;
  z-index: 1;
  overflow-y: auto;
}

.conversationsContainer.open {
  width: 400px;
}

.widgetContainer {
  /* background-color: yellow; */
  flex: 1;
  min-width: 320px;
  overflow-y: auto;
}

.clientsContainer {
  flex: 1;
  min-width: 320px;
  overflow-y: auto;
  padding: 1rem;
}

.toggleButton {
  position: absolute;
  right: 5px;
  top: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 0;
  transition: all 0.2s ease;
}

.toggleButton:hover {
  background-color: #f3f4f6;
}

.toggleIcon {
  color: #6b7280;
}

.toggleButton:hover .toggleIcon {
  color: #374151;
}

/* New Tab Styles */
.tabsContainer {
  display: flex;
  gap: 1px;
  background-color: #f3f4f6;
  padding: 0 1rem;
  height: 48px;
  border-bottom: 1px solid #e5e7eb;
}

.tabButton {
  padding: 0 1.5rem;
  height: 100%;
  background: none;
  border: none;
  font-size: 0.875rem;
  font-weight: 500;
  color: #6b7280;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.tabButton:hover {
  color: #111827;
}

.activeTab {
  color: #111827;
}

.activeTab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #2563eb;
}

.priorityTagContainer {
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}
/** urgent / high / medium / low tags */
.urgentPriority {
  background-color: #fee2e2;
  color: #dc2626;
}

.highPriority {
  background-color: #fff3e6;
  color: #d97706;
}

.mediumPriority {
  background-color: #fef9c3;
  color: #ca8a04;
}

.lowPriority {
  background-color: #e0e7ff;
  color: #4f46e5;
}
.noContent {
  font-size: 14px;
  color: #4b5563;
  margin: 8px 0;
  line-height: 1.5;
}

/** popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popupContent {
  position: relative;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
}
.closeIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon:hover {
  background-color: #f3f4f6;
}
.eventItem {
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  cursor: pointer;
}

.eventItem:hover {
  background-color: #f3f4f6;
}

.eventItem:last-child {
  border-bottom: none;
}

.closeButton {
  width: 100%;
  margin-top: 16px;
  padding: 8px;
  background-color: #f3f4f6;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #111827;
}

.closeButton:hover {
  background-color: #e5e7eb;
}
