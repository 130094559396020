.top-segment {
  @apply rounded-lg bg-gray-100 px-2.5 text-sm font-medium text-gray-600 dark:bg-card dark:text-gray-500;
}

.side-menu-content.expanded {
  @apply max-w-[800px];
}

.separator {
  height: 1px;
  width: calc(100% - 32px); /* 100% minus padding (16px on each side) */
  margin: 8px 16px;
}
.icon-size-16-16 {
  width: 16px; /* Or your desired size */
  height: 16px;
}
.icon-size-20-20 {
  width: 20px; /* Or your desired size */
  height: 20px;
}
.icon-size-22-22 {
  width: 22px; /* Or your desired size */
  height: 22px;
}
.icon-size-24-24 {
  width: 24px; /* Or your desired size */
  height: 24px;
}
.icon-size-32-32 {
  width: 32px; /* Or your desired size */
  height: 32px;
}
