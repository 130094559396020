/* todayTaskWidget.module.css */
.container {
  width: 100%;
  position: relative;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  max-width: 100%;
  overflow: hidden;
  height: 318px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

h2.title {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--finecoBlack);
}

.cardsWrapper {
  width: 100%;
  overflow: hidden;
  position: relative;
  max-width: 100%; /* Ensure it doesn't overflow container */
}

.cardsContainer {
  display: flex;
  gap: 1rem;
  transition: transform 0.3s ease-in-out;
  width: max-content; /* Allow natural width for scrolling */
}

.card {
  flex: 0 0 300px;
  min-width: 300px;
  max-width: 300px; /* Ensure consistent width */
  background: #f9fafb;
  border-radius: 0.5rem;
  padding: 1rem;
  height: 200px;
}

/* Rest of the styles remain the same */
.cardContent {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardHeader {
  margin-bottom: 0.5rem;
}

.cardTitle {
  font-weight: 500;
  color: #111827;
  margin-bottom: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
h3.cardTitle {
  color: var(--finecoBlack);
}

.cardInfo {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: #6b7280;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  background: #e5e7eb;
  color: #374151;
  font-size: 0.75rem;
}

.navigation {
  position: absolute;
  bottom: 10px;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  z-index: 10;
}

.navButton {
  padding: 0.5rem;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover {
  background: #f3f4f6;
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  position: relative;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 500px;
  max-height: 80vh;
  overflow-y: auto;
}

.closeIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon:hover {
  background-color: #f3f4f6;
}

.eventItem {
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  cursor: pointer;
}

.eventItem:hover {
  background-color: #f3f4f6;
}

.eventItem:last-child {
  border-bottom: none;
}

.closeButton {
  width: 100%;
  margin-top: 16px;
  padding: 8px;
  background-color: #f3f4f6;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #111827;
}

.closeButton:hover {
  background-color: #e5e7eb;
}
