.dot {
  animation: 1s blink infinite;
  fill: grey;
}
.dot:nth-child(2) {
  animation-delay: 250ms;
}
.dot:nth-child(3) {
  animation-delay: 500ms;
}
@keyframes blink {
  50% {
    fill: transparent;
  }
}

.assistantMarkdown table {
  @apply my-4;
}

.assistantMarkdown > .table-wrapper {
  @apply my-4 w-full overflow-x-auto pb-2;
}

.assistantMarkdown tr {
  @apply border-b border-gray-300;
}

.assistantMarkdown td,
.assistantMarkdown th {
  @apply px-2 py-1 text-left;
}

.assistantMarkdown pre {
  @apply my-8;
}

.assistantMarkdown p {
  margin-top: 0;
}

.assistantMarkdown h3 {
  @apply mt-4 text-lg font-bold text-black dark:text-white;
}

.assistantMarkdown ul,
.assistantMarkdown ol {
  @apply my-4 ml-4;
}

.assistantMarkdown ul {
  @apply list-disc;
}

.assistantMarkdown ul ul {
  list-style: circle outside none;
}

.assistantMarkdown ol {
  @apply list-decimal;
}

.assistantMarkdown li p {
  @apply inline-block;
}

.assistantMarkdown table {
  @apply my-4;
}

.assistantMarkdown > .table-wrapper {
  @apply my-4 w-full overflow-x-auto pb-2;
}

.assistantMarkdown tr {
  @apply border-b border-gray-700;
}

.assistantMarkdown th {
  @apply bg-gray-100;
}

.activeShadow {
  filter: drop-shadow(0 0 0.15rem #00fd86);
}
.deActiveShadow {
  filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, 0.2));
}
