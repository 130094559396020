.table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
  /* background-color: white; */
}

.table th,
.table td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* background-color: white; */
}

.table th {
  /* border-radius: 8; */
  /* background-color: yellow; */
  font-weight: 500;
}
