.attachmentResponseContainer {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 8px;
  padding-left: 8px; /* Further reduced padding */
  background-color: #f5f7fa;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.attachmentResponseContainerSVG {
  display: flex;
  /* align-items: center; */
  gap: 0.5rem;
  border-radius: 8px;
  padding-top: 5px;
  padding-left: 8px; /* Further reduced padding */
  /* background-color: #f5f7fa; */
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.attachmentIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px; /* Smaller size */
  height: 32px; /* Smaller size */
  background-color: #ffffff;
}

.attachmentDetails {
  flex-grow: 1;
}

.attachmentDetails .fileName {
  font-size: 14px; /* Smaller font size */
  font-weight: 600;
  margin-left: 5px;
}

.attachmentDetails .fileInfo {
  font-size: 12px; /* Smaller font size */
  color: #6b7280; /* Gray color */
  margin-left: 5px;
}

.attachmentActions {
  display: flex;
  gap: 0.35rem; /* Reduced gap */
  margin-left: 5px;
}

.attachmentActionIcon {
  color: #1e88e5;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.attachmentActionIcon:hover {
  opacity: 0.6;
}
.bt1 {
  width: 50px;
  height: 50px;
  background-color: red;
}
.bt2 {
  width: 50px;
  height: 50px;
  background-color: yellow;
}
.btContainer {
  display: flex;
}
.expandStyle {
  /* color: var(--finecoBluePrimary); */
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.expandStyle:hover {
  opacity: 0.6;
}
.downloadStyle {
  width: 30px;
  height: 31px;
  margin-left: 5px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 150ms;
  cursor: pointer;
}

.downloadStyle:hover {
  opacity: 0.6;
}
