/* LatestLead.module.css */
.container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

h3.title {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.viewMore {
  font-size: 14px;
  color: #6b7280;
  background: none;
  border: none;
  cursor: pointer;
}

.viewMore:hover {
  color: #4b5563;
}

.leadCard {
  background: #f9fafb;
  border-radius: 6px;
  padding: 16px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.tags {
  display: flex;
  gap: 8px;
}

.tag {
  font-size: 12px;
  color: #6b7280;
  background: #f3f4f6;
  padding: 4px 8px;
  border-radius: 4px;
}

.menuButton {
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 4px;
}

.menuButton:hover {
  color: #4b5563;
}

.leadTitle {
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  margin: 0 0 16px 0;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.revenue {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.label {
  font-size: 12px;
  color: #6b7280;
}

.amount {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
}

.meeting {
  display: flex;
  align-items: center;
  gap: 8px;
}

.calendarIcon {
  color: #6b7280;
}

.date {
  font-size: 14px;
  color: #6b7280;
}
