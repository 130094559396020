.portfolioMainContainer {
  position: relative;
  max-width: 100%;
}
.portfolioContainer {
  max-width: 100%;
  overflow: hidden;
}
.portfolioContainerInner {
  width: 100%;
  background-color: var(--grayLight);
  padding: 20px;
  border-radius: 10px;
}
/* so so so sorry. but this is what we have now, and i have no time to fix */
.portfolioContainerInnerInner {
  display: flex;
  min-width: 0px;
  flex-direction: column;
}

.sectionContainer {
  width: 100%;
  min-width: 0px;
  overflow: hidden;
}
.sectionComponentContainer {
  max-width: 100%;
  /* overflow-x: auto; */
}
.title {
  color: var(--finecoBlack);
  font-size: 20px;
  padding: 10px;
}
.subTitle {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 600;
  color: var(--finecoBlack) !important;
}
.subTitleMargin {
  margin-bottom: 20px;
}

.buttonContainer {
  margin-top: 1rem /* 16px */;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 0.5rem /* 8px */;
}
.generateReportButton {
  white-space: nowrap;
  border-radius: 0.5rem /* 8px */;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  text-align: center;
  font-weight: 600;
  color: white;
  cursor: pointer;
  background-color: var(--finecoBluePrimary);
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  font-size: 12px;
  width: 163px;
}
.generateReportButton:hover {
  opacity: 0.8;
}

.bottom {
  padding-top: 2.5rem /* 40px */;
}

/* charts */
.tooltipContainer {
  background-color: white;
  padding: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  min-width: 150px;
}

.tooltipRow {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin: 4px 0;
}

.chartContainer {
  width: 100%;
  height: 500px;
}

.evolutionChart {
  height: 24rem /* 384px */;
  padding: 1rem /* 16px */;
  /* background-color: white; */
  /* border-radius: 8px; */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); */
}

.portfolioAnalysisTable {
  width: 100%;
  overflow-x: auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.performanceTable {
  width: 100%;
  /* padding: 10px; */
  /* margin-left: -10px; */
}
