/* clientDetailsSchedule.module.css */
.container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.searchIcon {
  position: absolute;
  left: 12px;
  color: #6b7280;
}

.searchInput {
  width: 100%;
  padding: 8px 12px 8px 36px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-size: 14px;
  color: #111827;
  background-color: white;
}

.searchInput::placeholder {
  color: #9ca3af;
}

.searchInput:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 1px #2563eb;
}

.noResults {
  text-align: center;
  padding: 20px;
  color: #6b7280;
  font-size: 14px;
}

.cardWrapper {
  background: #f9fafb;
  margin-bottom: 10px;
  border-radius: 12px;
  padding: 16px;
  width: 100%;
}

.card {
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cardContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 500;
}

h3.title {
  font-size: 15px;
  font-weight: 600;
  color: #111827;
}

.badge {
  font-size: 12px;
  padding: 4px 10px;
  background: #f3f4f6;
  border-radius: 16px;
  color: #4b5563;
  font-weight: 500;
}

.location {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #4b5563;
  font-size: 13px;
  margin: 2px 0;
}

.description {
  color: #374151;
  font-size: 14px;
  line-height: 1.5;
  margin: 4px 0;
}

.dateInfo {
  color: #6b7280;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
}

.status {
  font-size: 12px;
  padding: 4px 12px;
  background: #e5e7eb;
  border-radius: 16px;
  color: #4b5563;
  font-weight: 500;
}

.participants {
  display: flex;
  gap: 4px;
}

.participant {
  width: 26px;
  height: 26px;
  background: #e5e7eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #4b5563;
}
