@tailwind base;
@tailwind components;
@tailwind utilities;

h3 {
  @apply font-semibold;
}

h2 {
  @apply text-lg font-semibold;
}

html,
body {
  min-height: 100%;
  /* scrollbar-gutter: stable; */
}

p {
  @apply mt-2.5;
}

p > a {
  @apply text-sb-green-600 underline;
}

.green-btn {
  @apply bg-sb-green-1002 enabled:hover:bg-sb-green-1001 cursor-pointer whitespace-nowrap rounded-full px-8 py-2 font-semibold text-black duration-150 disabled:cursor-auto disabled:opacity-50;
}

.orange-btn {
  @apply h-fit cursor-pointer whitespace-nowrap rounded-full bg-orange-300  px-8 py-2 font-semibold text-white duration-150 enabled:hover:bg-orange-400 disabled:cursor-auto disabled:opacity-50;
}

.outline-btn {
  @apply cursor-pointer whitespace-nowrap rounded-full border border-black bg-transparent px-8 py-2 font-semibold text-black duration-150 hover:bg-sb-gray-100;
}

.green-white-btn {
  @apply bg-sb-green-1001 cursor-pointer whitespace-nowrap rounded-full px-8 py-2 font-semibold text-white duration-150 hover:bg-sb-green-700;
}

.red-btn {
  @apply cursor-pointer whitespace-nowrap rounded-full bg-sb-red-600 px-8 py-2 font-semibold text-black duration-150 hover:bg-sb-red-400;
}

.blank-btn {
  @apply cursor-pointer whitespace-nowrap rounded-full border border-black px-8 py-2 font-semibold text-black duration-150 hover:bg-black hover:text-white;
}

.black-btn {
  @apply cursor-pointer whitespace-nowrap rounded-full border border-black bg-black px-8 py-2 text-center font-semibold text-white duration-150 enabled:hover:bg-white enabled:hover:text-black disabled:cursor-auto disabled:opacity-50;
}

.black-btn-disabled {
  @apply cursor-auto whitespace-nowrap rounded-full border border-sb-gray-200 bg-sb-gray-200 px-8 py-2 text-center font-semibold text-sb-gray-500;
}

.login-btn {
  @apply flex w-[350px] cursor-pointer items-center justify-center gap-4 rounded-full border border-black py-3 duration-150 hover:bg-black hover:text-white;
}

.text-small-gray {
  @apply text-sm font-light text-sb-gray-700;
}

.tooltip {
  @apply absolute left-1/2 -translate-x-1/2 translate-y-1/2 rounded-md bg-sb-gray-100 p-4 text-base text-sb-gray-700 opacity-0 shadow-lg transition-opacity group-hover:opacity-100;
}

.progress-gradient {
  background: rgb(176, 115, 255);
  background: linear-gradient(
    90deg,
    rgba(176, 115, 255, 1) 0%,
    rgba(50, 157, 255, 1) 33%,
    rgba(5, 249, 249, 1) 66%,
    rgba(0, 253, 134, 1) 100%
  );
}

@keyframes blink {
  50% {
    fill: transparent;
  }
}
.dot {
  animation: 1s blink infinite;
  fill: grey;
}
.dot:nth-child(2) {
  animation-delay: 250ms;
}
.dot:nth-child(3) {
  animation-delay: 500ms;
}

.ai-response-bubbles > p:first-child {
  @apply rounded-tl-3xl;
}

.ai-response-bubbles:last-child {
  @apply mb-0;
}

.rounded-gray-card {
  @apply rounded-xl bg-sb-gray-100 p-4;
}

.rounded-small-card {
  @apply rounded-3xl border-2 border-sb-gray-300 bg-white px-4 py-2;
}

.rounded-selector {
  @apply w-fit cursor-pointer select-none rounded-full border px-2 py-0.5 text-sm duration-150;
}

.rounded-selector.active {
  @apply border-sb-green-700 bg-sb-green-500;
}

.rounded-selector.inactive {
  @apply border-sb-gray-200 bg-sb-gray-100 hover:border-sb-green-700 hover:bg-sb-green-400;
}

.skeleton {
  @apply animate-pulse select-none rounded-lg;
}
