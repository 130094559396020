.container {
  padding: 20px 0;
}

.title {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 20px;
}

.infoList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.infoRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

.leftSide {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #6b7280;
}

.statusBadge {
  background: #f3f4f6;
  padding: 4px 12px;
  border-radius: 16px;
  color: #6b7280;
}
.address {
  font-size: 12px;
  text-align: right;
}
