.button {
  color: white;
  width: 163px;
  background-color: var(--finecoBluePrimary);
  font-size: 12px;
  height: 36px;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: 600;
}
.button.whiteVariant {
  color: var(--finecoBluePrimary);
  background-color: white;
  border: 2px solid var(--finecoBluePrimary);
}
.button:hover {
  opacity: 0.8;
}
.notAllowed {
  cursor: not-allowed;
}
.allowed {
  cursor: pointer;
}
