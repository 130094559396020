/* src/shared/layout/SpeechBubble.module.css */
.speechBubble {
  position: absolute;
  background-color: var(--finecoBluePrimary);
  color: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  cursor: pointer; /* Change cursor to pointer */
  user-select: none;
}

.speechBubble.show {
  opacity: 1;
  visibility: visible;
}
.speechBubble::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent var(--finecoBluePrimary) transparent transparent;
}
.menuUserItemContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px;
  margin-bottom: 22px;
}
