/* Animation for custom dots */
.dot {
  animation: 1s blink infinite;
  fill: grey;
}
.dot:nth-child(2) {
  animation-delay: 250ms;
}
.dot:nth-child(3) {
  animation-delay: 500ms;
}
@keyframes blink {
  50% {
    fill: transparent;
  }
}

/* Markdown text styling */
.reactMarkdown {
  font-size: 16px;
  line-height: 1.5;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* List Styling */
.reactMarkdown ul {
  list-style-type: disc; /* Ensures bullet points display as dots */
  padding-left: 20px; /* Adds indentation for list */
}

.reactMarkdown ol {
  list-style-type: decimal; /* Ordered lists with numbers */
  padding-left: 20px; /* Adds indentation */
}

.reactMarkdown li {
  margin-bottom: 5px; /* Adds space between list items */
}

/* Table Styling */
.reactMarkdown table {
  margin: 1rem 0; /* Tailwind alternative for my-4 */
  width: 100%;
  border-collapse: collapse;
}

.reactMarkdown th,
.reactMarkdown td {
  padding: 0.5rem;
  border: 1px solid gray;
}

.reactMarkdown th {
  background-color: #e5e7eb; /* Tailwind's bg-gray-200 */
}

.reactMarkdown tr {
  border-bottom: 1px solid #374151; /* Tailwind's border-gray-700 */
}

/* Code block styling */
.reactMarkdown pre {
  margin: 2rem 0;
  max-width: 100%;
  overflow-x: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  background-color: var(--fineco-blue-light);
}

/* Paragraph styling */
.reactMarkdown p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Heading styling */
.reactMarkdown h3 {
  margin-top: 1rem;
  font-size: 1.125rem;
  font-weight: bold;
  color: black;
}

@media (prefers-color-scheme: dark) {
  .reactMarkdown h3 {
    color: white;
  }
}
ul {
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}

.suggested-prompt:hover {
  background-color: var(--fineco-blue-light) !important;
  color: var(--fineco-blue-primary) !important;
}
