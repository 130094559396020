/* PortfolioDistribution.css */
.portfolio-distribution {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  align-items: start;
  padding: 0px;
  margin-top: 10px;
  /* min-height: 300px !important; */
}

@media (max-width: 768px) {
  .portfolio-distribution {
    grid-template-columns: 1fr;
  }
}

/* PortfolioDistribution.css */
.portfolio-distribution2 {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px;
}

.portfolio-distribution2 > * {
  flex: 1;
}

@media (max-width: 768px) {
  .portfolio-distribution2 {
    flex-direction: column;
  }
}

.pie-chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 300px;
}

/* DistributionTable.css */
.table-container {
  text-align: center;
  margin-bottom: 20px !important;

  padding-top: 8px;
  font-size: 14px;
}

.distribution-table {
  width: 100%;
  border-collapse: collapse;
  color: black;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(28, 34, 38, 0.1);
  border-radius: 8px;
}

.distribution-table th,
.distribution-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;

  /* background-color: red; */
}

.distribution-table th {
  /* background-color: #f4f4f4; */
  font-weight: bold;
}

.distribution-table tbody tr:hover {
  background-color: #f9f9f9;
}

.color-box {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  margin-right: 8px;
  vertical-align: middle;
}
.distribution-table .total-row {
  font-weight: bold;
  border-top: 2px solid #000;
}

.distribution-table th:first-child,
.distribution-table td:first-child {
  width: 80%;
}

.distribution-table th:last-child,
.distribution-table td:last-child {
  width: 20%;
}
