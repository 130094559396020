/* ClientHeader.module.css */
.container {
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.titleBar {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

title {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.nextAction {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: #fff;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  margin-bottom: 24px;
  gap: 12px;
  cursor: pointer;
}

.bellIcon {
  color: #6b7280;
}

.chevronIcon {
  margin-left: auto;
  color: #6b7280;
}

.profileSection {
  padding: 20px 0;
}

.avatarContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.avatar {
  width: 120px;
  height: 120px;
  background: #9ca3af;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.initials {
  color: white;
  font-size: 36px;
  font-weight: 500;
}

h2.name {
  font-size: 20px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.actions {
  display: flex;
  gap: 12px;
}

.actionButton {
  padding: 8px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: white;
  color: #6b7280;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButton:hover {
  background: #f3f4f6;
}
