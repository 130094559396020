.checkboxSquare {
  height: 20px;
  width: 20px;
  border-radius: 5px;
  border: 2px solid var(--finecoBluePrimary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicatorSquare {
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: var(--finecoBluePrimary);
}
.label {
  color: var(--black);
  margin-left: 8px; /* Add some space between checkbox and label */
}
