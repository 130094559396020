/* latestLead.module.css */
.container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

h3.title {
  font-size: 16px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.viewMore {
  font-size: 14px;
  color: #0066cc;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
}

.viewMore:hover {
  text-decoration: underline;
}

.leadCard {
  background: #f9fafb;
  border-radius: 6px;
  padding: 16px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 12px;
}

.tags {
  display: flex;
  gap: 8px;
  align-items: center;
}

.tag {
  font-size: 12px;
  color: #6b7280;
  background: #ffffff;
  padding: 4px 8px;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
}

.infoIcon {
  color: #6b7280;
  display: flex;
  align-items: center;
  cursor: help;
}

.menuButton {
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
}

.menuButton:hover {
  color: #4b5563;
}

.leadTitle {
  font-size: 14px;
  color: #111827;
  margin: 0 0 16px 0;
  line-height: 1.5;
  font-weight: bold;
}
.leadDescription {
  font-size: 12px;
  color: #111827;
  margin: 0 0 16px 0;
  line-height: 1.5;
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.revenue,
.meeting {
  display: flex;
  align-items: center;
  gap: 8px;
}

.label {
  font-size: 14px;
  color: #6b7280;
  display: flex;
  align-items: center;
  gap: 4px;
}

.dollarIcon,
.calendarIcon {
  color: #6b7280;
}

.amount {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
}

.date {
  font-size: 14px;
  color: #6b7280;
}
