.create-portfolio-form input {
  @apply block rounded-xl border border-sb-gray-200 bg-white px-4 py-2 duration-150;
}

.create-portfolio-form input:not([disabled]) {
  @apply hover:border-sb-gray-500;
}

.create-portfolio-form label {
  @apply text-base font-medium mb-1 block;
}

.create-portfolio-form label:has(+ input:required)::after {
  @apply text-sb-red-500;
  content: " *";
}

.create-portfolio-form label:has(+ select:required)::after {
  @apply text-sb-red-500;
  content: " *";
}
