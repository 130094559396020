@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.cdnfonts.com/css/roobert");
@import url("https://fonts.cdnfonts.com/css/inter");

html,
body {
  max-width: 100%;
  @apply h-full text-base;
}

#root {
  @apply h-full;
}

html {
  height: 100%;
  @apply bg-sb-gray-50;
}

body {
  margin: 0;
  overflow: auto;
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply text-black;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select {
  border-right: 16px solid transparent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(123, 123, 123, 0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:read-only {
  @apply cursor-pointer;
}
