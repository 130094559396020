.kyc {
  @apply w-fit min-w-[150px] rounded-full px-4 py-1 text-center text-base;
}

.kyc.no-account {
  @apply bg-orange-200  text-orange-700;
}

.kyc.C {
  @apply bg-sb-gray-200 text-sb-gray-700;
}
.kyc.A {
  @apply bg-sb-red-200  text-sb-red-700;
}
.kyc.N {
  @apply bg-blue-200  text-blue-700;
}
.kyc.O {
  @apply bg-sb-green-200 text-sb-green-700;
}
.kyc.P {
  @apply bg-sb-violet-200  text-sb-violet-700;
}
.kyc.R{
  @apply bg-sb-red-200  text-sb-red-700;
}

.kyc.NOT_STARTED {
  @apply bg-orange-200  text-orange-700;
}

.kyc.IN_PROGRESS {
  @apply bg-blue-200  text-blue-700;
}

.kyc.COMPLETED {
  @apply bg-sb-green-200 text-sb-green-700;
}

.kyc.REJECTED {
  @apply bg-sb-red-200  text-sb-red-700;
}
.kyc.CLOSED {
  @apply bg-sb-gray-200 text-sb-gray-700;
}
.kyc.ABANDONED {
  @apply bg-sb-gray-200 text-sb-gray-700;
}
