/* ActivityLog.module.css */
.container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.cardWrapper {
  display: flex;
  gap: 1rem;
  background: #f9fafb;
  margin-bottom: 10px;
  border-radius: 15px;
  padding: 10px;
  width: 100%; /* Added to ensure full width */
}

.card {
  /* height: 150px; */
  min-width: 300px;
  width: 100%; /* Added to ensure full width */
}

.cardContent {
  width: 100%; /* Added to ensure full width */
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.badge {
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  background: #e5e7eb;
  color: #374151;
  font-size: 0.75rem;
}

.navigation {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  gap: 0.5rem;
  z-index: 10;
}

.navButton {
  padding: 0.5rem;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover {
  background: #f3f4f6;
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* h3.title {
  font-size: 14px;
  font-weight: 600;
  color: #111827;
  margin: 0;
} */
.titleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between; /* Changed to space between */
  align-items: center; /* Added to vertically center items */
  margin-bottom: 8px; /* Added for spacing */
}
h3.title {
  font-size: 14px;
  font-weight: 600;
  color: #111827;
  margin: 0;
  /* Remove any flex-grow or width constraints */
}

.date {
  font-size: 12px;
  color: #6b7280;
}

.amount {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  padding: 4px 12px;
  background: #f3f4f6;
  border-radius: 16px;
}

.description {
  font-size: 14px;
  color: #4b5563;
  margin: 8px 0;
  line-height: 1.5;
}

.duration {
  font-size: 14px;
  color: #4b5563;
  margin: 8px 0;
}

.participants {
  display: flex;
  gap: 4px;
  margin-top: 12px;
}

.participant {
  width: 24px;
  height: 24px;
  background: #e5e7eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #6b7280;
}

.priority {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px !important;
  font-weight: 500;
  margin-left: auto; /* This helps push it to the right */
}
.titleContainer {
  display: flex;
  width: 100%;
}
