.checkbox {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 2px solid var(--finecoBluePrimary);
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicator {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: var(--finecoBluePrimary);
}
.label {
  color: var(--finecoBlack);
  margin-left: 8px; /* Add some space between checkbox and label */
}
