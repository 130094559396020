.txt-attachment-scroll-margin {
  scroll-margin-top: 130px;
}
.csv-attachment-scroll-margin {
  scroll-margin-top: 130px;
}
.image-attachment-scroll-margin {
  scroll-margin-top: 130px;
}
.svg-attachment-scroll-margin {
  scroll-margin-top: 120px;
}
