.react-aria-DateRangePicker {
  @apply text-[color:var(--text-color)];
  --field-border: var(--spectrum-global-color-gray-400);
  --field-background: var(--spectrum-global-color-gray-50);
  --text-color: var(--spectrum-alias-text-color);
  --text-color-placeholder: var(--spectrum-global-color-gray-700);
  --text-color-invalid: var(--spectrum-global-color-red-600);
  --highlight-background: none;
  --highlight-foreground: white;
  --highlight-background-invalid: var(--spectrum-global-color-static-red-600);
}
.react-aria-DateRangePicker .react-aria-Group {
  @apply outline-none;
}
.react-aria-DateRangePicker .react-aria-Group[data-focus-within] {
  @apply border-[color:var(--highlight-background)] shadow-[0_0_0_1px_var(--highlight-background)];
}
.react-aria-DateRangePicker [slot="start"] + span {
  @apply px-1 py-0;
}
.react-aria-DateRangePicker [slot="end"] {
  @apply mr-8 flex-1;
}
.react-aria-DateRangePicker .react-aria-Button {
  @apply sticky right-0 ml-auto box-content h-[1.429rem] w-[1.429rem] shrink-0 appearance-none rounded border-2 border-solid border-[color:var(--page-background)] border-[none] p-0 align-middle text-[0.857rem] text-[white];
  background: slateblue;
  outline: none;
}
.react-aria-DateRangePicker .react-aria-Button[data-focus-visible] {
  @apply shadow-[0_0_0_2px_slateblue];
}
.react-aria-Button:disabled {
  @apply text-sb-gray-200 !cursor-default;
}
.react-aria-DateRangePicker [slot="description"] {
  @apply text-xs;
}
.react-aria-DateRangePicker [slot="errorMessage"] {
  @apply text-xs text-[color:var(--text-color-invalid)];
}
.react-aria-DateRangePicker[data-invalid] [slot="end"]:after {
  @apply mr-[-2rem] flex-1 text-end content-["🚫"] content-["🚫"_/_""];
  alt: " ";
}
.react-aria-DateInput {
  @apply flex;
}
.react-aria-DateSegment {
  @apply px-0.5 py-0 text-end tabular-nums focus:rounded focus:text-[color:var(--highlight-foreground)] focus:caret-transparent;
}
.react-aria-DateSegment[data-type="literal"] {
  @apply p-0;
}
.react-aria-DateSegment[data-placeholder] {
  @apply italic text-[color:var(--text-color-placeholder)];
}
.react-aria-DateSegment:focus {
  background: var(--highlight-background);
  outline: none;
}
.react-aria-DateSegment[data-invalid] {
  @apply text-[color:var(--text-color-invalid)] focus:text-[color:var(--highlight-foreground)];
}
.react-aria-DateSegment[data-invalid]:focus {
  background: var(--highlight-background-invalid);
}
.react-aria-RangeCalendar, .react-aria-Calendar {
  @apply w-fit text-[color:var(--text-color)];
  --highlight-background: #2bf076;
  --highlight-foreground: white;
  --button-background: var(--spectrum-global-color-gray-50);
  --button-background-pressed: var(--spectrum-global-color-gray-100);
  --button-border: var(--spectrum-global-color-gray-400);
  --text-color: var(--spectrum-alias-text-color);
  --text-color-disabled: var(--spectrum-alias-text-color-disabled);
  --text-color-invalid: var(--spectrum-global-color-red-600);
  --pressed-color: var(--spectrum-global-color-gray-200);
  --unavailable-color: var(--spectrum-global-color-red-600);
  --invalid-color: var(--spectrum-global-color-static-red-600);
}
.react-aria-RangeCalendar header, .react-aria-Calendar header {
  @apply mx-1 mb-2 mt-0 flex items-center;
}
.react-aria-RangeCalendar header .react-aria-Heading {
  @apply m-0 flex-1 text-center text-[1.375rem];
}
.react-aria-RangeCalendar header .react-aria-Button {
  @apply m-0 h-8 w-8 appearance-none rounded border border-solid border-[color:var(--button-border)] p-0 text-center align-middle text-base text-[color:var(--text-color)] shadow-[0_1px_2px_rgba(0_0_0_/_0.1)];
  background: var(--button-background);
  outline: none;
}
.react-aria-RangeCalendar header .react-aria-Button[data-focus-visible] {
  @apply border-[color:var(--highlight-background)] shadow-[0_0_0_1px_var(--highlight-background)];
}
.react-aria-RangeCalendar header .react-aria-Button[data-pressed] {
  background: var(--button-background-pressed);
}
.react-aria-RangeCalendar table {
  @apply border-collapse;
}
.react-aria-RangeCalendar table td {
  @apply px-0 py-0.5;
}
.react-aria-RangeCalendar .react-aria-CalendarCell {
  @apply w-[2.286rem] rounded-md text-center leading-[2.286rem] cursor-pointer;
  outline: none;
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-outside-month] {
  @apply hidden;
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-pressed] {
  background: var(--pressed-color);
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-focus-visible] {
  @apply shadow-[inset_0_0_0_2px_var(--highlight-background)];
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-selected] {
  @apply rounded-none text-[color:var(--highlight-foreground)];
  background: var(--highlight-background);
}
.react-aria-RangeCalendar
  .react-aria-CalendarCell[data-selected][data-focus-visible] {
  @apply shadow-[inset_0_0_0_1px_var(--highlight-background),inset_0_0_0_3px_var(--highlight-foreground)];
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-selection-start] {
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-selection-end] {
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-disabled] {
  @apply text-sb-gray-200 !cursor-default;
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-unavailable] {
  @apply text-[color:var(--unavailable-color)] line-through;
}
.react-aria-RangeCalendar .react-aria-CalendarCell[data-invalid] {
  @apply text-[color:var(--highlight-foreground)];
  background: var(--invalid-color);
}
.react-aria-RangeCalendar [slot="errorMessage"] {
  @apply text-xs text-[color:var(--text-color-invalid)];
}
.react-aria-Popover {
  @apply overflow-auto rounded-md border border-solid border-[color:var(--spectrum-global-color-gray-400)] p-5 shadow-[0_8px_20px_rgba(0_0_0_/_0.1)];
  background: var(--page-background);
}
.react-aria-Popover[data-placement="top"] {
  --origin: translateY(8px);
}
.react-aria-Popover[data-placement="bottom"] {
  --origin: translateY(-8px);
}
.react-aria-Popover[data-entering] {
  @apply animate-[slide_200ms];
}
.react-aria-Popover[data-exiting] {
  @apply animate-[slide_200ms_reverse_ease-in];
}
@keyframes slide {
  from {
    @apply opacity-0;
    transform: var(--origin);
  }
  to {
    @apply translate-y-0 opacity-100;
  }
}
@media (forced-colors: active) {
  .react-aria-DateRangePicker {
    forced-color-adjust: none;
    --field-border: ButtonBorder;
    --field-background: Field;
    --text-color: FieldText;
    --text-color-placeholder: FieldText;
    --text-color-invalid: LinkText;
    --highlight-background: Highlight;
    --highlight-foreground: HighlightText;
    --highlight-background-invalid: LinkText;
  }
  .react-aria-DateRangePicker .react-aria-Button {
    @apply border border-solid border-[ButtonBorder] text-[ButtonText];
    forced-color-adjust: none;
    --focus-ring-color: Highlight;
    background: ButtonFace;
  }
  .react-aria-DateRangePicker .react-aria-Button[data-focus-visible] {
    @apply border-[Highlight] shadow-[0_0_0_1px_Highlight];
  }
  .react-aria-RangeCalendar {
    forced-color-adjust: none;
    --highlight-background: Highlight;
    --highlight-foreground: HighlightText;
    --button-border: ButtonBorder;
    --button-border-disabled: GrayText;
    --button-background: ButtonFace;
    --text-color: ButtonText;
    --text-color-disabled: GrayText;
    --text-color-invalid: LinkText;
    --unavailable-color: GrayText;
    --invalid-color: LinkText;
    --pressed-color: Canvas;
    --spectrum-alias-background-color-default: Canvas;
  }
}
