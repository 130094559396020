/* TabNavigation.module.css */
.tabs {
  display: flex;
  gap: 32px;
  padding: 0 24px;
  border-bottom: 1px solid #e5e7eb;
}

.tab {
  position: relative;
  padding: 16px 0;
  background: none;
  border: none;
  font-size: 14px;
  color: #6b7280;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tab:hover {
  color: #111827;
}

.tab.active {
  color: #111827;
  font-weight: 500;
}

.tab.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: #111827;
}

.badge {
  padding: 2px 6px;
  background: #e5e7eb;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
}
