.react-tel-input {
  @apply z-50 mb-4 block w-full appearance-none rounded-xl border-0 bg-sb-gray-100 px-1 py-2;
}

.react-tel-input .form-control {
  @apply rounded-xl border-0 bg-sb-gray-100;
}

.react-tel-input .flag-dropdown {
  @apply rounded-xl border-0 bg-sb-gray-100;
}

.react-tel-input .selected-flag {
  @apply rounded-xl bg-sb-gray-100;
}

.react-tel-input .selected-flag.open {
  @apply bg-sb-gray-100 !important;
}

.react-tel-input .flag-dropdown.open {
  @apply bg-sb-gray-100 !important;
}

.react-tel-input .flag-dropdown:hover {
  @apply bg-sb-gray-100;
}

.react-tel-input .selected-flag:hover {
  @apply bg-sb-gray-100;
}

.react-tel-input .country-list {
  @apply max-h-[150px];
}
