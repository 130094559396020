/* clientList.module.css */
.container {
  width: 100%;
  position: relative;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

h2.title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 16px;
}

.counter {
  color: #6b7280;
  font-size: 14px;
}

.navigation {
  display: flex;
  gap: 4px;
}

.navButton {
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover {
  background-color: #f3f4f6;
}

.clientsList {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.clientCard {
  padding: 16px;
  background-color: #f9fafb;
  border-radius: 4px;
}

.clientInfo {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.nameSection {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  min-width: 200px;
}

.eyeIcon {
  color: #6b7280;
  margin-top: 2px;
}

.clientName {
  font-weight: 500;
  color: #111827;
  margin-bottom: 4px;
}

.clientAmount {
  color: #6b7280;
  font-size: 14px;
}

.messageSection {
  flex: 1;
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.message {
  flex: 1;
  margin: 0;
  color: #4b5563;
  font-size: 14px;
  line-height: 1.4;
}

.downloadButton {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
}

.downloadButton:hover {
  background-color: #f3f4f6;
}
