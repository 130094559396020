.data-table table {
  @apply border-separate border-spacing-y-3 text-base;
}

.data-table th {
  @apply text-left text-sm font-medium;
}

.data-table th div.sort {
  @apply flex items-center gap-2;
}

.data-table tbody tr {
  @apply bg-sb-gray-100 px-4 py-2 duration-150;
}

.data-table tbody tr.row {
  @apply cursor-pointer hover:bg-sb-gray-300;
}

.data-table tbody tr.row-skeleton {
  @apply animate-pulse;
}

.data-table tbody tr td:first-child {
  @apply rounded-l-xl pl-4 text-left;
}

.data-table tbody tr td:last-child {
  @apply rounded-r-xl pr-4 text-right;
}

.data-table tbody tr td:last-child div {
  @apply float-right;
}

.data-table thead tr th:first-child {
  @apply pl-4 text-left;
}

.data-table thead tr th:last-child {
  @apply pr-4 text-right;
}

.data-table tbody tr td {
  @apply py-2;
}
