/* calendarWidget.module.css */
.container {
  width: 100%;
  position: relative;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.monthDisplay {
  font-size: 20px;
  font-weight: 500;
  color: #111827;
}

.navigation {
  display: flex;
  gap: 4px;
}

.navButton {
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover {
  background-color: #f3f4f6;
}

.calendar {
  width: 100%;
}

.weekDays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  margin-bottom: 2px;
}

.weekDay {
  font-size: 12px;
  color: #6b7280;
  padding: 2px;
}

.daysGrid {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.week {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
}

.day {
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dayWithEvent {
  cursor: pointer;
}

.dayWithEvent:hover {
  background-color: #f3f4f6;
}

.dateNumber {
  font-size: 12px;
  color: #111827;
}

.otherMonth .dateNumber {
  color: #9ca3af;
}

.eventDot {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #1e40af;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  position: relative;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 70%;
  max-height: 90vh;
  overflow-y: auto;
}

.closeIcon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeIcon:hover {
  background-color: #f3f4f6;
}

.eventItem {
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
}

.eventItem:last-child {
  border-bottom: none;
}

.eventTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}

.eventType {
  font-size: 12px;
  color: #6b7280;
  margin-bottom: 4px;
}

.eventTime {
  font-size: 12px;
  color: #4b5563;
  margin-bottom: 4px;
}
.titleDay {
  font-size: 18px;
  font-weight: 600;
  color: black;
  margin-left: 10px;
}
.eventLocation {
  display: flex;
  font-size: 12px;
  color: #4b5563;
  font-style: italic;
  margin-bottom: 5px;
}
.eventLocation span {
  margin-left: 5px;
}

.closeButton {
  width: 100%;
  margin-top: 16px;
  padding: 8px;
  background-color: #f3f4f6;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: #111827;
}

.closeButton:hover {
  background-color: #e5e7eb;
}

.addButton {
  margin-left: 8px;
  background-color: #4caf50;
  color: white;
}

.addButton:hover {
  background-color: #45a049;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin-bottom: 0px;
}

.formRow {
  display: flex;
  gap: 0px;
}

.input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.textarea {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  resize: vertical;
}

.locationInput {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
}

.locationInput input {
  border: none;
  padding: 0;
  width: 100%;
}

.locationInput input:focus {
  outline: none;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.submitButton {
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #45a049;
}
.dateTimePickerWrapper {
  position: relative;
}

.dateTimeInput {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  background-color: white;
}

.dateTimeInput:hover {
  border-color: #aaa;
}

.dateTimePickerPopup {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 12px;
  margin-top: 4px;
}

.nativeInput {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 8px;
}

.pickerActions {
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;
  border-top: 1px solid #eee;
}

.confirmButton {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.confirmButton:hover {
  background-color: #45a049;
}
/* Add these to your calendarWidget.module.css */
:global(.react-datepicker-wrapper) {
  width: 100%;
}

:global(.react-datepicker__input-container) {
  width: 100%;
}

:global(.react-datepicker__time-list-item--selected) {
  background-color: #4caf50 !important;
}

:global(.react-datepicker__day--selected) {
  background-color: #4caf50 !important;
}

:global(.react-datepicker__day--keyboard-selected) {
  background-color: #45a049 !important;
}

:global(.react-datepicker__header) {
  background-color: #f8f8f8 !important;
}

:global(.react-datepicker__today-button) {
  background-color: #f8f8f8 !important;
  color: #4caf50 !important;
  font-weight: bold !important;
}

.locationInput {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
}

.locationField {
  border: none !important;
  padding: 0 !important;
}

.locationField:focus {
  outline: none;
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: #666;
}

.titleDay {
  margin: 0 0 24px 0;
  font-size: 1.5rem;
  font-weight: 600;
}

.eventForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formRow {
  display: flex;
  gap: 16px;
}

.formRow > .formGroup {
  flex: 1;
}

.input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.textarea {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  resize: vertical;
  min-height: 80px;
}

.locationInput {
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px 12px;
}

.locationInput svg {
  color: #666;
}

.locationField {
  border: none !important;
  padding: 0 !important;
}

.locationField:focus {
  outline: none;
}

.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 8px;
}

.closeButton {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #45a049;
}

/* DatePicker custom styles */
.dateTimeInput {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  width: 100%;
}

.dateTimeInput:hover {
  border-color: #aaa;
}

/* Global styles for react-datepicker */
:global(.react-datepicker-wrapper) {
  width: 100%;
}

:global(.react-datepicker__input-container) {
  width: 100%;
}

:global(.react-datepicker) {
  font-family: inherit;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

:global(.react-datepicker__header) {
  background-color: #f8f8f8;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

:global(.react-datepicker__current-month) {
  font-weight: 600;
  padding: 8px 0;
}

:global(.react-datepicker__day--selected) {
  background-color: #4caf50 !important;
  border-radius: 4px;
}

:global(.react-datepicker__time-container .react-datepicker__time) {
  background-color: white;
}

:global(
  .react-datepicker__time-container
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected
) {
  background-color: #4caf50;
  border-radius: 4px;
}

:global(.react-datepicker__today-button) {
  background-color: #f8f8f8;
  border-top: 1px solid #ddd;
  padding: 8px;
  font-weight: 600;
  color: #4caf50;
}

:global(.react-datepicker__day:hover) {
  background-color: #e8f5e9;
  border-radius: 4px;
}

:global(.react-datepicker__navigation) {
  top: 8px;
}

:global(.react-datepicker-popper) {
  z-index: 1100;
}
.participantButton {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #1a202c;
}

.participantButton:hover {
  background-color: #f7fafc;
}

.participantSelector {
  margin-top: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  max-height: 300px;
  overflow-y: auto;
}

.searchContainer {
  padding: 8px;
  border-bottom: 1px solid #e2e8f0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.searchInput {
  width: 100%;
  padding: 8px 32px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
}

.searchIcon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  color: #a0aec0;
}

.participantList {
  padding: 8px;
}

.participantItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.participantItem:hover {
  background-color: #f7fafc;
}

.participantItem.selected {
  background-color: #ebf8ff;
}

.checkIcon {
  color: #4299e1;
}

.errorMessage {
  color: #dc2626;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.participantButton {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #1a202c;
}

.participantButton:hover {
  background-color: #f7fafc;
}

/* Add a red border when there's a validation error */
.errorMessage + .participantSelector,
.errorMessage + button.participantButton {
  border-color: #dc2626;
}

/* Rest of the styles remain the same */
.errorMessage {
  color: #dc2626;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.inputError {
  border-color: #dc2626 !important;
}

.inputError:focus {
  border-color: #dc2626 !important;
  box-shadow: 0 0 0 1px #dc2626 !important;
}

.participantButton {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #1a202c;
}

.participantButton:hover {
  background-color: #f7fafc;
}

/* Add a red border when there's a validation error */
.errorMessage + .participantSelector,
.errorMessage + button.participantButton,
.participantButton.inputError {
  border-color: #dc2626;
}
.errorMessage {
  color: #dc2626;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #fef2f2;
  border-radius: 4px;
  border-left: 4px solid #dc2626;
}

.inputError {
  border-color: #dc2626 !important;
  background-color: #fef2f2 !important;
}

.inputError:focus {
  border-color: #dc2626 !important;
  box-shadow: 0 0 0 1px #dc2626 !important;
}

.participantButton.inputError {
  border-color: #dc2626;
  background-color: #fef2f2;
}
.errorMessage {
  color: #ffffff;
  font-size: 0.875rem;
  margin-top: 0.5rem;
  padding: 0.75rem;
  background-color: #dc2626;
  border-radius: 4px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(220, 38, 38, 0.2);
}

.inputError {
  border: 2px solid #dc2626 !important;
  background-color: #fef2f2 !important;
}

.inputError:focus {
  border: 2px solid #dc2626 !important;
  box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.2) !important;
}

.participantButton.inputError {
  border: 2px solid #dc2626 !important;
  background-color: #fef2f2 !important;
  color: #dc2626 !important;
}
.participantButton.inputError {
  border: 2px solid #dc2626 !important;
  background-color: #fee2e2 !important;
  color: #dc2626 !important;
}

.errorMessage {
  background-color: #dc2626;
  color: white !important;
  padding: 0.5rem;
  border-radius: 4px;
  font-weight: 500;
  margin-top: 0.5rem !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Animazione per far lampeggiare l'errore */
@keyframes errorPulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.errorMessage {
  animation: errorPulse 2s infinite;
}
.eventItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px;
  border-bottom: 1px solid #eee;
}

.eventContent {
  flex: 1;
  margin-right: 16px;
}

.editButton {
  flex-shrink: 0;
  padding: 4px 12px;
  /* rest of your button styles */
}
.mt2 {
  margin-top: 8px;
}
.selectedParticipantsList {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selectedParticipant {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #f3f4f6;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
}

.removeParticipant {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #6b7280;
}

.removeParticipant:hover {
  color: #ef4444;
}
.selectedParticipantsList {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selectedParticipant {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f3f4f6;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
}

.removeParticipant {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #6b7280;
}

.removeParticipant:hover {
  color: #ef4444;
}
