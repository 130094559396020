.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.gridContainer {
  display: grid;
  gap: 10px;
  justify-content: center;
  align-content: center;
}

/* Grid layouts based on item count */
.gridSingle {
  grid-template-columns: 1fr;
}

.gridDouble {
  grid-template-columns: repeat(2, 1fr);
}

.gridTriple {
  grid-template-columns: repeat(3, 1fr);
}

.gridQuad {
  grid-template-columns: repeat(4, 1fr);
}

.gridItemContainer {
  width: 230px;
  height: 110px;
  background-color: #fff;
  text-align: center;
  padding: 0;
  border-radius: 15px;
  font-size: 26px;
  box-shadow: 0px 4px 8px 0px rgba(28, 34, 38, 0.08);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.gridItemContainer:hover {
  background-color: var(--finecoBlueLight);
  transform: translateY(-2px);
  box-shadow: 0px 6px 12px 0px rgba(28, 34, 38, 0.12);
}

.gridItem {
  display: flex;
  text-align: left;
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
}

.iconPrompt {
  flex-shrink: 0;
  width: 18px;
  height: 21px;
  min-width: 18px;
  min-height: 21px;
  margin-right: 15px;
}

/* Responsive rules */
@media (max-width: 1100px) {
  /* Large tablet and small desktops */
  .gridQuad {
    grid-template-columns: repeat(3, 1fr); /* Cap at gridTriple */
  }
}

@media (max-width: 768px) {
  /* Medium screen (tablet and small laptops) */
  .gridTriple {
    grid-template-columns: repeat(2, 1fr); /* Cap at gridDouble */
  }

  .gridQuad {
    grid-template-columns: repeat(2, 1fr); /* Cap at gridDouble */
  }
}

@media (max-width: 540px) {
  /* Small screen (mobile, 540px and below) */
  .wrapper {
    align-items: flex-start; /* Align grid to top */
    padding-top: 20px; /* Add spacing at the top */
  }

  .gridContainer {
    overflow-y: auto; /* Enable vertical scrolling */

    max-height: calc(
      100vh - 20px
    ); /* Prevent overflow beyond the screen height */
    padding: 10px; /* Inner padding for better UX */
    grid-template-columns: 1fr; /* Force single column */
  }

  .gridItemContainer {
    position: relative;
    /* top: 150px; */
    width: calc(100% - 20px); /* Ensure items fit screen width */
    max-width: 500px; /* Optional max width */
  }
}
