.container {
  width: 100%;
  position: relative;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

h2.title {
  font-size: 24px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.headerRight {
  display: flex;
  align-items: center;
  gap: 16px;
}

.counter {
  color: #6b7280;
  font-size: 14px;
}

.navigation {
  display: flex;
  gap: 4px;
}

.navButton {
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover:not(:disabled) {
  background-color: #f3f4f6;
}

.navButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.toolbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding: 8px 0;
  border-bottom: 1px solid #e5e7eb;
}

.leftToolbar,
.rightToolbar {
  display: flex;
  align-items: center;
  gap: 16px;
}

.toolbarIcon {
  color: #6b7280;
  cursor: pointer;
}

.toolbarIcon:hover {
  color: #374151;
}

.sortButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background: white;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
}

.sortButton:hover {
  background-color: #f3f4f6;
}

.rotated {
  transform: rotate(180deg);
}

.tableContainer {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th {
  text-align: left;
  padding: 12px 16px;
  color: #6b7280;
  font-weight: 500;
  font-size: 14px;
  border-bottom: 1px solid #e5e7eb;
}

.table td {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  color: #111827;
  font-size: 14px;
}

.checkboxColumn {
  width: 40px;
}

.checkbox {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.priority {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
}

.addButton {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: #4b5563;
  color: white;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.addButton:hover {
  background-color: #374151;
}
