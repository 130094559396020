.mainContainer {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.button {
  color: var(--white);
  background-color: var(--finecoBluePrimary);

  border-radius: 0.5rem;
  text-align: center;
  font-weight: 600;

  padding-top: 0.5rem /* 8px */;
  padding-bottom: 0.5rem /* 8px */;
}
.label {
  color: var(--finecoBlack) !important;
  margin-left: 8px !important;
}

.redCircle {
  width: 10px;
  height: 10px;
  background-color: #bb2525; /* Red color */
  border-radius: 50%;
}
.icon-size-16-16 {
  width: 16px; /* Or your desired size */
  height: 16px;
}
.icon-size-20-20 {
  width: 20px; /* Or your desired size */
  height: 20px;
}
.icon-size-22-22 {
  width: 22px; /* Or your desired size */
  height: 22px;
}
.icon-size-24-24 {
  width: 24px; /* Or your desired size */
  height: 24px;
}
.iconSize34_34 {
  width: 34px; /* Or your desired size */
  height: 34px;
}
