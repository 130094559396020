/* ActivityLog.module.css */
.container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.timelineItem {
  display: flex;
  gap: 16px;
  position: relative;
}

.timelineItem:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 15px;
  top: 32px;
  bottom: -24px;
  width: 2px;
  background-color: #e5e7eb;
}

.timelineIcon {
  width: 32px;
  height: 32px;
  background: #f3f4f6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280;
  z-index: 1;
}

.timelineContent {
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

h4.title {
  font-size: 14px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.date {
  font-size: 12px;
  color: #6b7280;
}

.amount {
  font-size: 14px;
  font-weight: 500;
  color: #111827;
  padding: 4px 12px;
  background: #f3f4f6;
  border-radius: 16px;
}

.description {
  font-size: 14px;
  color: #4b5563;
  margin: 8px 0;
  line-height: 1.5;
}

.duration {
  font-size: 14px;
  color: #4b5563;
  margin: 8px 0;
}
