.grid-container-3x3 {
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 24px 10px;
  width: 100%;
}
.grid-container-2x3 {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 24px 10px;
  width: 100%;
}
.grid-container-1x3 {
  display: grid;
  grid-template-columns: 1.1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 24px 10px;
  width: 100%;
}
.grid-container-2x2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 6px 10px;
  width: 100%;
}

.grid-item {
  padding: 0px;
  text-align: left;
}

.constraint-submit-button {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.5rem;
  width: 120px;
  cursor: "pointer";
  text-align: center;
  font-weight: 600;
  align-items: end;
  align-self: flex-end;
  margin-top: 10px;
}

.minMax {
  position: absolute;
  font-size: 12px;
  color: #70767d;
  background-color: white;
  margin-top: -40px;
  margin-left: 30px;
  padding-left: 5px;
  padding-right: 5px;
}
