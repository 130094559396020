.container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.searchContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.searchIcon {
  position: absolute;
  left: 12px;
  color: #6b7280;
}

.searchInput {
  width: 100%;
  padding: 8px 12px 8px 36px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  font-size: 14px;
  color: #111827;
  background-color: white;
}

.searchInput::placeholder {
  color: #9ca3af;
}

.searchInput:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 1px #2563eb;
}

.noResults {
  text-align: center;
  padding: 20px;
  color: #6b7280;
  font-size: 14px;
}

.cardWrapper {
  display: flex;
  gap: 1rem;
  background: #f9fafb;
  margin-bottom: 10px;
  border-radius: 15px;
  padding: 10px;
  width: 100%;
  height: auto;
}

.card {
  min-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cardContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.cardFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.titleContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

h3.title {
  font-size: 14px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.date {
  font-size: 12px;
  color: #6b7280;
}

.description {
  font-size: 14px;
  color: #4b5563;
  margin: 8px 0;
  line-height: 1.5;
}

.copy {
  margin-top: 10px;
  font-size: 14px;
}

/* Main popup container */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  position: relative;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80%;
  max-width: 70%;
  max-height: 90vh;
  overflow-y: auto;
}

/* Close icon */
.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: #666;
}

/* Title styling */
.titleDay {
  margin: 0 0 24px 0;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Form styling */
.eventForm {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* Input fields */
.input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
}

.textarea {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  resize: vertical;
  min-height: 80px;
}

/* Participants selector */
.participantButton {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #1a202c;
}

.participantSelector {
  margin-top: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: white;
  max-height: 300px;
  overflow-y: auto;
}

.selectedParticipantsList {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.selectedParticipant {
  display: flex;
  align-items: center;
  gap: 6px;
  background-color: #f3f4f6;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
}

.removeParticipant {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #6b7280;
}

.removeParticipant:hover {
  color: #ef4444;
}

/* Form actions */
.formActions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.closeButton {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton {
  padding: 8px 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #45a049;
}

/* Search container for participants */
.searchContainer {
  padding: 8px;
  border-bottom: 1px solid #e2e8f0;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.searchInput {
  width: 100%;
  padding: 8px 32px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
}

.participantList {
  padding: 8px;
}

.participantItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.participantItem:hover {
  background-color: #f7fafc;
}

.participantItem.selected {
  background-color: #ebf8ff;
}

.checkIcon {
  color: #4299e1;
}
.addButton {
  display: flex;
  justify-self: end;
  background-color: #4caf50;
  color: white;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.addButton:hover {
  background-color: #45a049;
}
/* .navButton {
  padding: 4px;
  border-radius: 50%;
  border: 1px solid #e5e7eb;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navButton:hover {
  background-color: #f3f4f6;
} */
