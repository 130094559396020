.agentTitle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 14px;
  color: var(--finecoBluePrimary);
}

.chatUserMessage {
  display: inline-block;
  background-color: var(--finecoBlueLight);
  color: var(--finecoBluePrimary);
  padding: 12px 16px;
  border-radius: 14px 14px 2px 14px;
  text-align: left;
  font-size: 14px;
}
.chatUserMessageTime {
  font-size: 10px;
  color: var(--finecoBlack);
  text-align: right;
}
@media (min-width: 1024px) {
  .agentTitle {
    /* font-size: 1.125rem;  */
    /* line-height: 1.75rem;  */
  }
}
.chatCopyButton {
  display: flex;
  margin-left: 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
}
