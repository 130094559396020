.microphone-container {
  position: absolute;
  bottom: 2px;
  width: 46px;
  height: 46px;
  border-radius: 20%;
  background-color: #e5f1fb;
  z-index: 50;
  right: 0px;
}
.microphone-container:hover {
  opacity: 0.6;
}
