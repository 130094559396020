.chatCancel {
  position: relative;
  top: 50%;
  transform: translateY(12%);
  cursor: pointer;
}
.chatCancel:hover {
  opacity: 0.6;
}

.chatTextInput {
  overflow: hidden;
  height: 50px;
  resize: none;
  border-radius: 12px;
  padding-left: 48px;
  padding-right: 48px;
  padding-top: 12px;
  font-size: 16px;
}
.chatTextInput:focus {
  padding-top: 11px;
  border: 2px solid var(--finecoBluePrimary) !important;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 84, 159, 0.1);
}
