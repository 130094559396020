/* crmClientDetailsPage.module.css */
.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding-left: 14px;
  min-height: 100vh;
  background: #f3f4f6;
}

.backButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background: none;
  border: none;
  color: #111827;
  font-size: 12px;
  font-weight: 500;
  padding: 8px;
  cursor: pointer;
  transition: color 0.2s;
}

.backButton:hover {
  color: #4f46e5;
}

.content {
  display: grid;
  grid-template-columns: 380px 1fr;
  gap: 24px;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.rightColumn {
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.tabContent {
  flex: 1;
  padding: 24px;
  overflow-y: auto;
}

/* common */
.participants {
  display: flex;
  margin-top: 12px;
}

.participant {
  position: relative;
  margin-left: -8px;
  width: 32px;
  height: 32px;
}

.participant:first-child {
  margin-left: 0;
}

.participantAvatar {
  width: 32px;
  height: 32px;
  background: #e5e7eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #6b7280;
  border: 2px solid white;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

.participant:hover .participantAvatar {
  z-index: 2;
}

.participantTooltip {
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 8px;
  padding: 4px 8px;
  background-color: #1f2937;
  color: white;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 10;
}

.participant:hover .participantTooltip {
  opacity: 1;
  visibility: visible;
}

.participantTooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #1f2937 transparent transparent transparent;
}
.participantsPopup {
  position: absolute;
  top: -100px;
  left: -100px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  padding: 12px;
  z-index: 100;
  min-width: 200px;
}

.participantsList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.participantItem {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 4px 8px;
  border-radius: 4px;
}

.participantItem:hover {
  background: #f3f4f6;
}

.moreParticipants {
  width: 32px;
  height: 32px;
  background: #e5e7eb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #6b7280;
  border: 2px solid white;
  cursor: pointer;
  margin-left: -8px;
  position: relative;
  z-index: 1;
}

.moreParticipants:hover {
  z-index: 2;
}
