.container {
  width: 100%;
  position: relative;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 5px;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

h2.title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.contestList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contestItem {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
}

.contestInfo {
  min-width: 200px;
}

.nameSection {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contestName {
  font-weight: 500;
  color: #111827;
  font-size: 14px;
}

.contestAmount {
  color: #6b7280;
  font-size: 14px;
  font-weight: 500;
}

.progressContainer {
  display: flex;
  height: 8px;
  flex: 1;
  border-radius: 4px;
  overflow: hidden;
}

.progressBar {
  height: 100%;
  background-color: #94a3b8;
  transition: width 0.3s ease;
}

.remainingBar {
  height: 100%;
  background-color: #e5e7eb;
  transition: width 0.3s ease;
}
