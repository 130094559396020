.fee-template-form {
  @apply grid grid-cols-2 gap-y-4;
}

.fee-template-form > div label {
  @apply my-auto;
}

.fee-template-form > input {
  @apply rounded-xl border border-sb-gray-200 bg-white px-4 py-2 duration-150;
}

.fee-template-form > input:not([disabled]) {
  @apply hover:border-sb-gray-500;
}

.fee-template-form > input:disabled input:read-only {
  @apply cursor-not-allowed bg-sb-gray-200 border-sb-gray-400;
}

.fee-template-form > select {
  @apply rounded-xl border border-sb-gray-200 bg-white px-4 py-2 duration-150 hover:border-sb-gray-500 h-11 cursor-pointer box-border;
}

.fee-template-form > label:has(+ input:required)::after {
  @apply text-sb-red-500;
  content: " *"
}

.fee-template-form > label:has(+ select:required)::after {
  @apply text-sb-red-500;
  content: " *"
}

.fee-template-form-section {
  @apply my-10;
}
