.tab.active {
  @apply border-b-[3px] border-b-sb-green-700 pb-2 -mb-[3px] box-border;
}

.tab {
  @apply px-4 outline-none;
}

.custom-toast .Toastify__progress-bar.success {
  @apply bg-sb-green-700;
}

.custom-toast .Toastify__toast-body.success {
  @apply fill-sb-green-700;
}
