.yesno-switch {
  @apply border px-10 py-1.5;
}

.yesno-switch-wrapper div:nth-child(1) {
  @apply rounded-l-xl;
}

.yesno-switch-wrapper div:nth-child(2) {
  @apply rounded-r-xl;
}

.yesno-switch.inactive {
  @apply border-sb-gray-200 cursor-pointer hover:bg-sb-green-800 hover:border-sb-green-800 hover:text-white duration-150;
}

.yesno-switch.active {
  @apply border border-sb-green-700 bg-sb-green-700 px-10 py-1.5 text-white;
}
