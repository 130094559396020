.crmModal {
  width: 80% !important;
  max-width: 80% !important;
  height: 700px !important;
  background-color: var(--finecoBlueLight) !important;
  padding: 40px;
}
.tabList {
  background-color: var(--finecoBlueLight) !important;
}
