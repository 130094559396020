.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.75rem;
  transition: all 150ms;
  color: var(--menuCopy);
}

.menuItem:hover {
  background-color: var(--menuBackgroundHover);
  color: var(--menuHover);
}

.menuItem.active {
  background-color: var(--menuBackgroundActive);
  color: var(--menuActive);
}

.icon {
  transition: all 150ms;
  color: var(--menuIcon);
}

.active .icon {
  color: var(--menuIconActive);
}

.menuItem:hover .icon {
  color: var(--menuIconHover);
}

.text {
  display: none;
}

@media (min-width: 1280px) {
  .text {
    display: block;
  }
}
