.table {
  border-collapse: collapse;
  table-layout: fixed;
  width: 100%;
}

.table th,
.table td {
  border-bottom: 1px solid #ddd;
  padding: 8px;
}

.table th {
  /* background-color: #f2f2f2; */
  font-weight: 500;
}

.table td {
  vertical-align: middle;
}

.table thead th {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}
